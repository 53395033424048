import styled from "styled-components";
import DelieveryAddress from "../../components/checkout/DelieveryAddress";
import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import {
  DEFAULT_CURRENCY,
  freeShippingThreshold,
  getShippingCostPrice,
} from "../../utils/global_const";
import { ProductContext } from "../../context/ProductContext";
import CartProductBig from "../../components/product/CartProductBig";
import { useNavigation } from "../../utils/navigation";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EmptyCart } from "../../assets/icons/empty_cart.svg";
import { AuthUserContext } from "../../context/AuthUserContext";
import CartProductSaveForLater from "../../components/product/CartProductSaveForLater";
import CartCardSFL from "../../components/product/CartCardSFL";
import { useTranslation } from "react-i18next";

const Container = styled.main`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: var(--background-color-hover);
`;
const Wrapper = styled.div`
  padding-top: var(--spacing-xxl);
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
    position: relative; /* Ovo je potrebno za relativno pozicioniranje unutar roditelja */
  }
  @media (min-width: 1025px) {
    width: var(--max-width-container);
  }
`;

const LeftWrapper = styled.div`
  @media (min-width: 768px) {
    width: calc(100% - 420px);
  }

  @media (min-width: 768px) and (max-width: 1040px) {
    width: calc(100% - 260px);
  }
`;
const Title = styled.h1`
  padding: var(--spacing-xl) var(--spacing-md);
  margin: 0;
  line-height: normal;
  white-space: nowrap;
  @media (min-width: 768px) {
    padding: var(--spacing-xl) 0;
  }
`;

const RightContainer = styled.div`
  @media (min-width: 768px) {
    width: 400px;
    position: sticky;
    height: 500px;
    margin-top: calc(var(--spacing-xxl) + var(--spacing-xl) * 2);
  }
  @media (min-width: 768px) and (max-width: 1040px) {
    right: 10px;
    top: calc(
      var(--navbar-height-desktop) + var(--spacing-xxl) +
        var(--font-size-xxlarge) + var(--spacing-xl) * 2
    );

    width: 240px;
  }
  @media (min-width: 1025px) {
    left: calc(
      (100vw - var(--max-width-container)) / 2 + var(--max-width-container) -
        400px
    );
    top: calc(
      var(--navbar-height-desktop) + var(--spacing-xxl) +
        var(--font-size-xxlarge) + var(--spacing-xl) * 2
    );
  }

  display: flex;
  align-items: flex-start;
`;
const RightWrapper = styled.div`
  background-color: var(--background-color);
  border-top: 4px solid var(--primary-color);
  padding: var(--spacing-lg);
  width: 100%;
`;
const RightTitle = styled.h3`
  color: var(--text-color);

  font-size: var(--font-size-base);
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.7;
`;
const BrT = styled.div`
  height: 1px;
  background-color: var(--text-color);
  width: 100%;
  margin: var(--spacing-lg) 0;
  opacity: 0.1;
`;
const PriceFlex = styled.div`
  margin-bottom: var(--spacing-lg);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Subtotal = styled.h4`
  font-weight: 400;
`;
const BrD = styled.div`
  height: 0px;
  width: 100%;
  margin: var(--spacing-lg) 0;
  border-top: 1px dashed var(--text-color);
  opacity: 0.3;
`;
const GrandFlex = styled(PriceFlex)`
  margin: 0;
`;
const GrandTotal = styled(Subtotal)`
  font-weight: 700;
`;
const Savings = styled.p`
  color: var(--success-color);
  font-size: var(--font-size-small);
  font-style: italic;
`;
const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
`;
const CheckoutContainer = styled.div`
  width: 100%;
  background-color: var(--background-color);
`;
const CheckoutWrapper = styled.div`
  padding: var(--spacing-xl) 0;

  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (min-width: 768px) {
    padding: var(--spacing-xxl);
    justify-content: flex-end;
  }
`;
const CheckoutButton = styled.button`
  white-space: nowrap;
  padding: 10px 40px;
  color: var(--background-color);
  background-color: var(--text-color);
  &:hover {
    color: var(--text-color);
    background-color: var(--background-color);
  }
  margin: 0;
`;

const EmptyCartContainer = styled.div`
  height: 50vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  flex-direction: column;
`;
const EmptyCartText = styled.p`
  color: var(--p-color);
  font-size: var(--font-size-small);
  @media (min-width: 768px) {
    font-size: var(--font-size-base);
  }
`;

const StyledEmptyCartIcon = styled(EmptyCart)`
  width: 140px; /* Širina za mobilne uređaje */
  height: 140px; /* Visina za mobilne uređaje */
  @media (min-width: 768px) {
    width: auto; /* Širina za mobilne uređaje */
    height: auto; /* Visina za mobilne uređaje */
  }
`;
const GrandTotalMobile = styled(GrandTotal)`
  @media (min-width: 768px) {
    display: none;
  }
`;

const SFLContainer = styled.div`
  border-top: 1px solid #d7e0e7;
  background-color: var(--background-color-hover);
  padding-top: calc(var(--navbar-height) + var(--navbar-mini));
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    padding-top: calc(var(--navbar-height-desktop));
  }
`;
const SFLWrapper = styled.div`
  width: 94%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  @media (min-width: 1025px) {
    width: var(--max-width-container);
    gap: 12px;
  }
`;
const SFLProductWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const SFLGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);
  gap: 4px;

  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1025px) {
    width: var(--max-width-container);
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 12px;
  }
`;

const Cart = () => {
  const { t } = useTranslation();
  const { goToCheckout } = useNavigation();
  const navigate = useNavigate();
  const { currencyTag } = useContext(ProductContext);
  const cartItems = useSelector((state) => state.cart.itemsList);
  const saveForLaterItems = useSelector(
    (state) => state.saveForLater.itemsList
  );
  const subtotal = cartItems.reduce(
    (total, item) => total + item.product.discount_price * item.quantity,
    0
  );
  const subtotalRounded = parseFloat(subtotal.toFixed(2));
  const savings = cartItems.reduce(
    (totalSavings, item) =>
      totalSavings +
      (item.product.price - item.product.discount_price) * item.quantity,
    0
  );
  const savingsRounded = parseFloat(savings.toFixed(2));
  const [shippingCost, setShippingCost] = useState(0.0);
  const currency = localStorage.getItem("currency") || DEFAULT_CURRENCY;
  useEffect(() => {
    if (freeShippingThreshold > subtotalRounded) {
      setShippingCost(getShippingCostPrice(currency, DEFAULT_CURRENCY));
    } else {
      setShippingCost(0);
    }
  }, [subtotalRounded]);

  const grandTotal = subtotalRounded + shippingCost;
  const grandTotalRounded = parseFloat(grandTotal.toFixed(2));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container>
        {cartItems.length === 0 ? (
          <EmptyCartContainer>
            <StyledEmptyCartIcon style={{ marginBottom: "40px" }} />
            <Title className="title">{t("CART.TITLE_EMPTY_CART")}</Title>
            <EmptyCartText>{t("CART.TEXT_EMPTY_CART")}</EmptyCartText>
          </EmptyCartContainer>
        ) : (
          <Wrapper>
            <LeftWrapper>
              <Title className="title">{t("CART.TITLE_CART")}</Title>

              <ProductContainer>
                {cartItems.map((item, index) => (
                  <CartProductBig item={item} key={index} />
                ))}
              </ProductContainer>
              <CheckoutContainer>
                <CheckoutWrapper>
                  <GrandTotalMobile>
                    {currencyTag}
                    {grandTotalRounded}
                  </GrandTotalMobile>
                  <CheckoutButton
                    onClick={() => navigate(goToCheckout())}
                    className="button-transparent"
                  >
                    {t("BUTTONS.PROCEED_TO_BUY")}
                  </CheckoutButton>
                </CheckoutWrapper>
              </CheckoutContainer>
            </LeftWrapper>
            <RightContainer>
              <RightWrapper>
                <RightTitle>{t("RECEIPT.PRICE_DETAILS")}</RightTitle>
                <BrT />
                <PriceFlex>
                  <Subtotal>{t("RECEIPT.SUBTOTAL")}</Subtotal>{" "}
                  <Subtotal>
                    {currencyTag}
                    {subtotalRounded}
                  </Subtotal>
                </PriceFlex>
                <PriceFlex>
                  <Subtotal>{t("RECEIPT.DELIVERY_CHARGES")}</Subtotal>{" "}
                  {shippingCost > 0 ? (
                    <Subtotal>
                      {currencyTag}
                      {shippingCost}
                    </Subtotal>
                  ) : (
                    <div style={{ display: "flex", gap: "8px" }}>
                      <Subtotal style={{ textDecoration: "line-through" }}>
                        {currencyTag}
                        {getShippingCostPrice(currency, "USD")}
                      </Subtotal>
                      <Subtotal style={{ textTransform: "uppercase" }}>
                        {t("RECEIPT.FREE")}
                      </Subtotal>
                    </div>
                  )}
                </PriceFlex>
                <BrD />
                <GrandFlex>
                  <GrandTotal>{t("RECEIPT.GRAND_TOTAL")}</GrandTotal>{" "}
                  <GrandTotal>
                    {currencyTag}
                    {grandTotalRounded}
                  </GrandTotal>
                </GrandFlex>
                <BrD />
                <Savings>
                  {t("RECEIPT.SAVINGS")} {currencyTag}
                  {savingsRounded}
                </Savings>
              </RightWrapper>
            </RightContainer>
          </Wrapper>
        )}
      </Container>
      {saveForLaterItems.length !== 0 && (
        <SFLContainer>
          <SFLWrapper>
            <Title className="title">{t("CART.SAVE_FOR_LATER")}</Title>
            <SFLProductWrapper>
              <SFLGrid>
                {saveForLaterItems.map((item, index) => (
                  <CartCardSFL
                    product={item.product}
                    quantity={item.quantity}
                    key={index}
                  />
                ))}
              </SFLGrid>
            </SFLProductWrapper>
          </SFLWrapper>
        </SFLContainer>
      )}
    </>
  );
};

export default Cart;
