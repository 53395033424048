import styled from "styled-components";
import DelieveryAddress from "../../components/checkout/DelieveryAddress";
import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import {
  DEFAULT_CURRENCY,
  freeShippingThreshold,
  getShippingCostPrice,
} from "../../utils/global_const";
import { ProductContext } from "../../context/ProductContext";
import { useNavigation } from "../../utils/navigation";
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../context/AuthUserContext";
import FillAddress from "../../components/checkout/FillAddress";
import Payment from "../../components/checkout/Payment";
import Tooltip from "../../components/popups/Tooltip";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: var(--background-color-hover);
  min-height: 100vh;
  padding: 0;
`;
const Wrapper = styled.div`
  padding: var(--spacing-xs) 0;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  @media (min-width: 1025px) {
    width: var(--max-width-container);
  }
  @media (min-width: 768px) {
    padding: var(--spacing-xxl) 0;
    flex-direction: row;
  }
`;

const LeftWrapper = styled.div`
  @media (min-width: 768px) {
    width: calc(100% - 420px);
  }
  @media (min-width: 768px) and (max-width: 1040px) {
    width: calc(100% - 260px);
  }
`;
const Title = styled.h1`
  display: none;
  padding: 0;
  margin: 0;
  line-height: normal;
  @media (min-width: 768px) {
    display: block;
  }
`;

const RightContainer = styled.div`
  display: ${(props) => (props.$show ? "flex" : "none")};
  /* Fiksna širina za desni kontejner */
  /* Fiksirano pozicioniranje */
  @media (min-width: 768px) and (max-width: 1040px) {
    position: fixed;
    display: flex;
    width: 400px;
    right: 10px; /* Fiksiraj uz desnu ivicu */
    top: calc(
      var(--navbar-height-desktop) + var(--spacing-xxl) + var(--spacing-lg)
    );
    width: 240px;
  }
  @media (min-width: 1025px) {
    position: fixed;
    display: flex;
    width: 400px;
    left: calc(
      (100vw - var(--max-width-container)) / 2 + var(--max-width-container) -
        400px
    ); /* Fiksiraj uz desnu ivicu */
    top: calc(
      var(--navbar-height-desktop) + var(--spacing-xxl) + var(--spacing-lg)
    );
  }

  border-top: 4px solid var(--primary-color);

  align-items: center;
  flex-direction: column;
  gap: 8px;
  //margin-bottom: calc(var(--font-size-xxlarge) + 10px);
`;
const RightWrapper = styled.div`
  //padding: var(--spacing-lg);

  width: 100%;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
`;
const BottomRightWrapper = styled(RightWrapper)`
  width: 94%;
  display: none;
  @media (min-width: 768px) {
    display: flex;
    width: 100%;
  }
`;
const RightBox = styled.div`
  padding: var(--spacing-lg);

  width: 100%;
  background-color: var(--background-color);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;
const RightTitle = styled.h3`
  color: var(--text-color);

  font-size: var(--font-size-base);
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.7;
`;
const BrT = styled.div`
  height: 1px;
  background-color: var(--text-color);
  width: 100%;
  margin: var(--spacing-lg) 0;
  opacity: 0.1;
`;
const PriceFlex = styled.div`
  margin-bottom: var(--spacing-lg);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Subtotal = styled.h4`
  font-weight: 400;
`;
const BrD = styled.div`
  height: 0px;
  width: 100%;
  margin: var(--spacing-lg) 0;
  border-top: 1px dashed var(--text-color);
  opacity: 0.3;
`;
const GrandFlex = styled(PriceFlex)`
  margin: 0;
  cursor: pointer;
  @media (min-width: 768px) {
    cursor: auto;
  }
`;
const GrandTotal = styled(Subtotal)`
  font-weight: 700;
  text-transform: uppercase;
  color: var(--primary-color);

  @media (min-width: 768px) {
    text-transform: capitalize;
    color: var(--text-color);
  }
`;
const ArrowGrand = styled.svg`
  @media (min-width: 768px) {
    display: none;
  }
`;

const Savings = styled.p`
  color: var(--p-color);
  font-size: var(--font-size-small);
  font-weight: 700;
  color: var(--success-color);
  font-style: italic;
`;
const CoinText = styled(Savings)`
  color: var(--text-color);
  font-weight: 400;
`;
const CoinWorth = styled.p`
  display: inline;
  padding: 2px 18px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 37px;
  background: rgba(86, 147, 191, 0.2);
  color: #4e4e4e;
`;

const StepContainer = styled.div`
  width: 100%;
  background-color: var(--background-color);
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;
const StepWrapper = styled.div`
  padding: var(--spacing-xl);
  margin: var(--spacing-lg) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StepTitle = styled.h3`
  font-size: var(--font-size-base);
  font-weight: normal;
  display: flex;
  gap: 14px;
  text-transform: uppercase;
  align-items: center;
`;
const StepInfo = styled.div`
  font-size: var(--font-size-small);
  font-weight: normal;
  color: var(--p-color);
  display: flex;
  margin-top: var(--spacing-md);
  gap: var(--spacing-md);
`;
const StepContainerMB = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    display: none;
  }
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: var(--shadow-large);
`;
const StepWrapperMB = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: var(--spacing-md) 0;
  width: 90%;
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-md);
`;
const StepNumber = styled.div`
  border: 1px solid var(--text-color);

  border-radius: 50%;
  max-height: calc(var(--font-size-base));
  min-height: calc(var(--font-size-base));
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-small);
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  @media (min-width: 768px) {
    font-size: var(--font-size-base);
  }
`;
const IconTextDiv = styled.div`
  position: relative;
`;
const StepText = styled.div`
  position: absolute;
  top: calc(100% + var(--spacing-xxs));
  left: -14px;
  color: var(--p-color);
  z-index: 1;
  font-size: var(--font-size-small);
`;
const ActiveStepNumber = styled(StepNumber)`
  background-color: var(--primary-color);
  color: var(--background-color);
  border-color: var(--background-color-hover);
`;
const BrMiddle = styled.div`
  height: 0px;
  width: 70%;
  margin: var(--spacing-lg) 0;
  border-top: 1px solid var(--text-color);
  opacity: 0.1;
`;

//HEADER
const ContainerHeader = styled.div`
  display: flex;
  height: var(--navbar-mini);
  position: absolute;
  background-color: var(--background-color-hover);
  width: 100%;

  justify-content: center;
  z-index: var(--zindex-top);
  top: 0;
  left: 0;
  @media (min-width: 768px) {
    display: none;
  }
`;
const WrapperHeader = styled.div`
  max-width: var(--max-width-container);
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-sm);
  font-weight: 500;
  cursor: pointer;
`;
const ContainerGrand = styled.div`
  width: 100%;
  background-color: var(--background-color);
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.15);
`;
const WrapperGrand = styled.div`
  padding: var(--spacing-xl);
`;

const SelectAddressContainer = styled.div`
  width: 100%;
  background-color: var(--background-color);
  @media (min-width: 768px) {
    display: none;
  }
`;
const SelectAddressWrapper = styled.div`
  padding: var(--spacing-xl) var(--spacing-md);
  margin: var(--spacing-xxs) 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: 768px) {
    align-items: center;
    padding: var(--spacing-xl);
    flex-direction: row;
  }
`;
const AddressTitle = styled.h3`
  font-size: var(--font-size-base);
  font-weight: normal;
`;
const AdressInfo = styled.p`
  font-size: var(--font-size-small);
  font-weight: normal;
  color: var(--p-color);
`;
const AddressType = styled.p`
  display: inline;
  font-size: var(--font-size-small);
  font-family: "Larken";
  margin-top: 0;
  padding: var(--spacing-xs) var(--spacing-md);
  border-radius: 20px;
  text-transform: capitalize;
  color: var(--text-color);
  background-color: #d7e0e7;
  margin-left: 14px;
`;
const CheckoutMain = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [useCoins, setUseCoins] = useState(false);
  const [newAddress, setNewAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [editAddress, setEditAddress] = useState(null);
  const { userProfile } = useContext(AuthUserContext);
  const { currencyTag } = useContext(ProductContext);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [showPriceContainer, setShowPriceContainer] = useState(true);
  const handleShowContainer = () => {
    if (!isMobile) {
      return;
    }
    setShowPriceContainer(!showPriceContainer);
  };

  const cartItems = useSelector((state) => state.cart.itemsList);
  const subtotal = cartItems.reduce(
    (total, item) => total + item.product.discount_price * item.quantity,
    0
  );
  const subtotalRounded = parseFloat(subtotal.toFixed(2));
  const savings = cartItems.reduce(
    (totalSavings, item) =>
      totalSavings +
      (item.product.price - item.product.discount_price) * item.quantity,
    0
  );
  const savingsRounded = parseFloat(savings.toFixed(2));
  const [shippingCost, setShippingCost] = useState(0.0);
  const currency = localStorage.getItem("currency") || DEFAULT_CURRENCY;

  useEffect(() => {
    if (freeShippingThreshold > subtotalRounded) {
      setShippingCost(
        getShippingCostPrice(currency, DEFAULT_CURRENCY).toFixed(2)
      );
    } else {
      setShippingCost(0);
    }
  }, [subtotalRounded]);

  const [grandTotal, setGrandTotal] = useState(() => {
    const initialTotal = subtotalRounded + shippingCost;
    return parseFloat(initialTotal.toFixed(2));
  });

  const [grandTotalRounded, setGrandTotalRounded] = useState(grandTotal);
  const [pointsSpent, setPointsSpent] = useState(0);

  useEffect(() => {
    let newGrandTotal = Number(subtotalRounded) + Number(shippingCost);
    let pointsToSpend = 0;

    if (useCoins) {
      const maxDeduction = Number(userProfile.points) * 10;
      if (maxDeduction > Number(subtotalRounded)) {
        pointsToSpend = Number(subtotalRounded) / 10;
        newGrandTotal = Number(shippingCost);
      } else {
        pointsToSpend = Number(userProfile.points);
        newGrandTotal -= maxDeduction;
      }
    }

    const roundedTotal = parseFloat(newGrandTotal.toFixed(2));
    setGrandTotal(newGrandTotal);
    setGrandTotalRounded(roundedTotal);

    setPointsSpent(parseFloat(pointsToSpend.toFixed(4)));
  }, [useCoins, subtotalRounded, shippingCost, userProfile.points]);

  return (
    <>
      {selectedAddress && (
        <SelectAddressContainer>
          <SelectAddressWrapper>
            <FlexDiv style={{ alignItems: "flex-start" }}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g style={{ mixBlendMode: "multiply" }}>
                  <path
                    d="M11.5165 4.30969V15.2674C11.052 15.4881 10.5614 15.6631 10.0508 15.7865C9.47413 15.9261 8.87193 15.9999 8.2523 15.9999C4.04323 15.9999 0.631104 12.588 0.631104 8.3789C0.631104 6.88255 1.06234 5.48701 1.80713 4.30969H11.5165Z"
                    fill="#E6E6E6"
                  />
                </g>
                <path
                  d="M15.6212 7.62134C15.6212 11.2108 13.1397 14.2208 9.79853 15.0291C9.22183 15.1686 8.61948 15.2425 8 15.2425C3.79094 15.2425 0.378662 11.8304 0.378662 7.62134C0.378662 3.41227 3.79079 0 7.99985 0C12.2089 0 15.6068 3.39773 15.621 7.59469C15.621 7.60348 15.621 7.61255 15.621 7.62134H15.6212Z"
                  fill="#39B54A"
                />
                <g style={{ mixBlendMode: "multiply" }}>
                  <path
                    d="M13.1336 6.36771L7.0962 11.3049C6.97334 11.4052 6.82757 11.4591 6.68037 11.4685C6.45323 11.483 6.22278 11.3914 6.06766 11.2017L3.68594 8.28925C3.43028 7.97669 3.47651 7.51622 3.78906 7.26056L4.49598 6.68242C4.50865 6.67205 4.52147 6.66225 4.53458 6.65289C4.7175 6.6575 4.89927 6.72966 5.03884 6.86923L6.55507 8.38546L6.70788 8.23264L6.86546 8.42536L11.6302 4.52899C11.9046 4.30473 12.293 4.31279 12.5566 4.52971L12.6574 4.63053C12.6574 4.63053 12.6583 4.63154 12.6587 4.63211L12.757 4.75238L13.2367 5.33903C13.4923 5.65158 13.446 6.11205 13.1335 6.36771H13.1336Z"
                    fill="#CCCCCC"
                  />
                </g>
                <g style={{ mixBlendMode: "multiply" }}>
                  <path
                    d="M15.6213 7.62131C15.6213 11.2107 13.1398 14.2207 9.79868 15.0291L6.03223 11.2626L3.33926 8.56948C3.05033 8.28055 3.05033 7.81201 3.33926 7.52308L3.99288 6.86946C4.14224 6.72025 4.33942 6.64809 4.53501 6.65313C4.71794 6.65774 4.8997 6.7299 5.03927 6.86946L6.5555 8.38569L6.70832 8.23288L10.961 3.98017C11.2438 3.69744 11.6985 3.69124 11.9886 3.96202L15.6213 7.59481C15.6213 7.6036 15.6213 7.61267 15.6213 7.62146V7.62131Z"
                    fill="#E6E6E6"
                  />
                </g>
                <path
                  d="M12.6607 5.68006L7.07828 11.2625C6.96579 11.3748 6.82623 11.4435 6.68047 11.4683C6.45189 11.5075 6.20833 11.4388 6.03174 11.2625L3.33877 8.56936C3.04984 8.28043 3.04984 7.81189 3.33877 7.52296L3.99239 6.86934C4.14175 6.72012 4.33893 6.64796 4.53452 6.653C4.71745 6.65761 4.89922 6.72977 5.03878 6.86934L6.55501 8.38557L6.70783 8.23275L10.9605 3.98005C11.2433 3.69731 11.698 3.69112 11.9881 3.9619C11.9944 3.96781 12.0007 3.97386 12.0069 3.98005L12.5566 4.52968L12.6606 4.63367C12.6667 4.63986 12.6727 4.64606 12.6786 4.65239C12.7081 4.68379 12.7342 4.71735 12.7571 4.75235C12.9447 5.03926 12.9126 5.42801 12.6606 5.68006H12.6607Z"
                  fill="white"
                />
              </svg>
              <div>
                <div style={{ justifyContent: "flex-start" }}>
                  <AddressTitle>
                    {t("CHECKOUT.DELIVER_TO")} {selectedAddress.country},{" "}
                    {selectedAddress.id}
                    <AddressType>{selectedAddress.type}</AddressType>
                  </AddressTitle>
                </div>
                <AdressInfo>
                  {selectedAddress.street}, {selectedAddress.city},{" "}
                  {selectedAddress.postal_code} ..
                </AdressInfo>
              </div>
            </FlexDiv>
          </SelectAddressWrapper>
        </SelectAddressContainer>
      )}
      <ContainerHeader>
        <WrapperHeader
          onClick={() => {
            if (newAddress) {
              setNewAddress(false);
              setEditAddress(null);
            } else if (selectedAddress) {
              setSelectedAddress(null);
              setEditAddress(null);
            } else {
              navigate(-1);
            }
          }}
        >
          <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_693_9628)">
              <path
                d="M16.7742 8L3.22578 8"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.09668 4.12903L3.22571 8"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.09668 11.871L3.22571 8"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_693_9628">
                <rect
                  width="20"
                  height="15.4839"
                  fill="white"
                  transform="translate(20 15.7419) rotate(-180)"
                />
              </clipPath>
            </defs>
          </svg>
          {selectedAddress
            ? t("CHECKOUT.PAYMENT")
            : newAddress
            ? t("CHECKOUT.ADD_DELIVERY_ADDRESS")
            : t("CHECKOUT.SELECT_ADDRESS")}
        </WrapperHeader>
      </ContainerHeader>
      {!selectedAddress && (
        <StepContainerMB>
          <StepWrapperMB>
            <IconTextDiv>
              <ActiveStepNumber>1</ActiveStepNumber>
              <StepText style={{ color: "var(--text-color)" }}>
                {t("CHECKOUT.ADDRESS")}
              </StepText>
            </IconTextDiv>
            <BrMiddle />
            <IconTextDiv>
              <StepNumber
                style={{
                  color: "var(--p-color)",
                  borderColor: "var(--p-color)",
                }}
              >
                2
              </StepNumber>
              <StepText>{t("CHECKOUT.PAYMENT")}</StepText>
            </IconTextDiv>
          </StepWrapperMB>
        </StepContainerMB>
      )}
      <Container>
        <Wrapper>
          <LeftWrapper>
            <Title className="title">{t("CHECKOUT.CHECKOUT_TITLE")}</Title>
            <StepContainer>
              <StepWrapper>
                <FlexDiv>
                  <StepNumber>1</StepNumber>
                  <div>
                    <StepTitle>
                      {t("CHECKOUT.LOGIN")}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_325_5687)">
                          <path
                            d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                            fill="#027646"
                          />
                          <path
                            d="M10.0001 0C9.70174 0 9.40643 0.0130859 9.11475 0.0386719C14.2229 0.486758 18.2294 4.77551 18.2294 10C18.2294 15.2245 14.2229 19.5132 9.11475 19.9613C9.40643 19.9869 9.70174 20 10.0001 20C15.5229 20 20.0001 15.5229 20.0001 10C20.0001 4.47715 15.5229 0 10.0001 0Z"
                            fill="#027646"
                          />
                          <path
                            d="M8.61003 13.2705C8.42807 13.2705 8.24616 13.201 8.10733 13.0622L5.76799 10.7229C5.12206 10.0769 6.12499 9.06906 6.77338 9.71749L8.60999 11.5541L13.2266 6.93746C13.8671 6.29703 14.8872 7.28773 14.232 7.94285L9.11272 13.0622C8.97389 13.201 8.79198 13.2705 8.61003 13.2705Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_325_5687">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </StepTitle>
                    <StepInfo>
                      <span>{userProfile.referral_code}</span>
                      <span>{userProfile.email}</span>
                    </StepInfo>
                  </div>
                </FlexDiv>
              </StepWrapper>
            </StepContainer>

            {selectedAddress ? (
              <>
                <StepContainer>
                  <StepWrapper>
                    <FlexDiv>
                      <StepNumber>2</StepNumber>
                      <div>
                        <div>
                          <StepTitle>
                            {t("CHECKOUT.DELIVER_TO")}
                            {selectedAddress.country}, {selectedAddress.id}
                          </StepTitle>
                          <StepInfo>
                            {selectedAddress.street}, {selectedAddress.city},{" "}
                            {selectedAddress.postal_code} ..
                          </StepInfo>
                        </div>
                      </div>
                    </FlexDiv>
                    <button
                      className="button-transparent"
                      onClick={() => setSelectedAddress(null)}
                    >
                      {t("BUTTONS.CHANGE")}
                    </button>
                  </StepWrapper>
                </StepContainer>
                <Payment
                  useCoins={useCoins}
                  setUseCoins={setUseCoins}
                  selectedAddress={selectedAddress}
                />
              </>
            ) : (
              <FillAddress
                setSelectedAddress={setSelectedAddress}
                newAddress={newAddress}
                setNewAddress={setNewAddress}
                editAddress={editAddress}
                setEditAddress={setEditAddress}
              />
            )}
          </LeftWrapper>

          {showPriceContainer ? (
            <RightContainer $show={selectedAddress}>
              <RightWrapper>
                <RightBox>
                  <RightTitle>{t("RECEIPT.PRICE_DETAILS")}</RightTitle>
                  <BrT />
                  <PriceFlex>
                    <Subtotal>{t("RECEIPT.SUBTOTAL")}</Subtotal>{" "}
                    <Subtotal>
                      {currencyTag}
                      {subtotal.toFixed(2)}
                    </Subtotal>
                  </PriceFlex>
                  {useCoins && (
                    <PriceFlex style={{ position: "relative" }}>
                      <Subtotal>
                        {t("RECEIPT.COINS_LABEL")} <Tooltip />
                      </Subtotal>{" "}
                      <div>
                        <Subtotal>
                          -{currencyTag}
                          {(pointsSpent * 10).toFixed(2)} (-
                          <svg
                            width="17"
                            height="18"
                            viewBox="0 0 27 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.3594 27.3594C20.7376 27.3594 26.7188 21.3782 26.7188 14C26.7188 6.62182 20.7376 0.640625 13.3594 0.640625C5.9812 0.640625 0 6.62182 0 14C0 21.3782 5.9812 27.3594 13.3594 27.3594Z"
                              fill="#FEDA2C"
                            />
                            <path
                              d="M13.3595 24.298C19.0471 24.298 23.6579 19.6873 23.6579 13.9996C23.6579 8.31194 19.0471 3.70117 13.3595 3.70117C7.6718 3.70117 3.06104 8.31194 3.06104 13.9996C3.06104 19.6873 7.6718 24.298 13.3595 24.298Z"
                              fill="#FCAA17"
                            />
                            <path
                              d="M13.3595 6.08545L15.7016 10.8311L20.9385 11.5921L17.1491 15.2861L18.0437 20.5019L13.3595 18.0393L8.67527 20.5019L9.56988 15.2861L5.78027 11.5921L11.0174 10.8311L13.3595 6.08545Z"
                              fill="#FEDA2C"
                            />
                          </svg>
                          {pointsSpent} )
                        </Subtotal>
                      </div>
                    </PriceFlex>
                  )}
                  <PriceFlex>
                    <Subtotal>{t("RECEIPT.DELIVERY_CHARGES")}</Subtotal>{" "}
                    <div>
                      <Subtotal>
                        {currencyTag}
                        {shippingCost}
                      </Subtotal>
                    </div>
                  </PriceFlex>

                  <BrD />
                  <GrandFlex onClick={handleShowContainer}>
                    <GrandTotal>
                      {t("RECEIPT.GRAND_TOTAL")}
                      <ArrowGrand
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_729_9593)">
                          <path
                            d="M4 10L8 6L12 10"
                            stroke="#5693BF"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_729_9593">
                            <rect
                              width="16"
                              height="16"
                              fill="white"
                              transform="matrix(1 0 0 -1 0 16)"
                            />
                          </clipPath>
                        </defs>
                      </ArrowGrand>
                    </GrandTotal>{" "}
                    <GrandTotal>
                      {currencyTag}
                      {grandTotalRounded}
                    </GrandTotal>
                  </GrandFlex>
                  <BrD />
                  <Savings>
                    {t("RECEIPT.SAVINGS")} {currencyTag}
                    {savingsRounded}
                  </Savings>
                  <BrD />
                  <Savings>
                    {t("RECEIPT.EARN_COINS")} {grandTotalRounded * 0.01}
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 27 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.3594 27.3594C20.7376 27.3594 26.7188 21.3782 26.7188 14C26.7188 6.62182 20.7376 0.640625 13.3594 0.640625C5.9812 0.640625 0 6.62182 0 14C0 21.3782 5.9812 27.3594 13.3594 27.3594Z"
                        fill="#FEDA2C"
                      />
                      <path
                        d="M13.3595 24.298C19.0471 24.298 23.6579 19.6873 23.6579 13.9996C23.6579 8.31194 19.0471 3.70117 13.3595 3.70117C7.6718 3.70117 3.06104 8.31194 3.06104 13.9996C3.06104 19.6873 7.6718 24.298 13.3595 24.298Z"
                        fill="#FCAA17"
                      />
                      <path
                        d="M13.3595 6.08545L15.7016 10.8311L20.9385 11.5921L17.1491 15.2861L18.0437 20.5019L13.3595 18.0393L8.67527 20.5019L9.56988 15.2861L5.78027 11.5921L11.0174 10.8311L13.3595 6.08545Z"
                        fill="#FEDA2C"
                      />
                    </svg>
                  </Savings>
                </RightBox>
              </RightWrapper>
              <BottomRightWrapper>
                <RightBox>
                  <RightTitle
                    style={{
                      color: "var(--primary-color)",
                    }}
                  >
                    {t("RECEIPT.COINS_LABEL")}
                  </RightTitle>

                  <BrT />
                  <CoinText>{t("RECEIPT.COIN_TOOLTIP")}</CoinText>
                  <CoinWorth>{t("RECEIPT.COIN_WORTH")}</CoinWorth>
                </RightBox>
              </BottomRightWrapper>
            </RightContainer>
          ) : (
            <ContainerGrand onClick={handleShowContainer}>
              <WrapperGrand>
                <GrandFlex>
                  <GrandTotal>
                    {t("RECEIPT.GRAND_TOTAL")}
                    <ArrowGrand
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_729_9720)">
                        <path
                          d="M4 6L8 10L12 6"
                          stroke="#5693BF"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_729_9720">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </ArrowGrand>
                  </GrandTotal>{" "}
                  <GrandTotal>
                    {currencyTag}
                    {grandTotalRounded}
                  </GrandTotal>
                </GrandFlex>
              </WrapperGrand>
            </ContainerGrand>
          )}
        </Wrapper>
      </Container>
    </>
  );
};

export default CheckoutMain;
