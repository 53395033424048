import styled, { css } from "styled-components";
import HeroSection from "../../components/pages/HeroSection";
import ProductCardBG from "../../components/product/ProductCardBG";
import { useNavigation } from "../../utils/navigation";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ProductContext } from "../../context/ProductContext";
import BannerHorizont from "../../components/pages/BannerHorizont";
import myIcon from "../../assets/icons/pouche.svg";

import Subscription from "../../components/pages/Subscription";
import RecentBlogs from "../../components/pages/RecentBlogs";
import { useTranslation } from "react-i18next";

const Wrapper = styled.main`
  background-color: var(--background-color-hover);
`;
const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const ProductGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 4px;
  @media (max-width: 1025px) {
    justify-items: center;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }

  @media (min-width: 1025px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 12px;
  }
`;
const MaxWidthWrapper = styled.div`
  margin: var(--spacing-xxl) 0;
  width: calc(100% - 24px);
  @media (min-width: 1025px) {
    width: var(--max-width-container);
  }
`;
const ProductSectionTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: var(--spacing-md) 0;
`;
const ProductSectionTitle = styled.h3`
  font-family: "Larken";
  text-align: left;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-size: var(--font-size-xxlarge);
  color: var(--text-color);
  cursor: pointer;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;
const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  margin: var(--spacing-md) 0;
`;
const MoreContainer = styled(ProductWrapper)`
  background-color: #f3f3f2;
`;
const MoreTitle = styled(ProductSectionTitle)`
  text-align: center;
  margin-bottom: 40px;
`;
const MoreMid = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;
const MoreItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //gap: 8px;
`;
const MoreSectionTitle = styled.h3`
  font-family: "Larken-Light";
  text-align: left;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-size: var(--font-size-xlarge);
  color: var(--text-color);
  cursor: pointer;
  //max-width: 60%;
`;
const MoreText = styled.p`
  font-weight: 400;
  font-size: var(--font-size-base);
  opacity: 0.5;
  text-align: center;
  max-width: 100%;
  @media (min-width: 768px) {
    max-width: 60%;
  }
`;

const Button = styled.button`
  &:hover svg path {
    stroke: var(--background-color); /* Postavite željenu boju ovde */
  }
`;
const Svg = styled.svg`
  cursor: pointer;
`;

const HomeMain = () => {
  const { t } = useTranslation();
  const { goToBestSellers, goToNewArrivals, goToBlog } = useNavigation();
  const navigate = useNavigate();
  const {
    loadBestSellers,
    bestSellers,
    loadNewArrivals,
    newArrivals,
    products,
    loadProducts,
  } = useContext(ProductContext);

  useEffect(() => {
    if (products.length) {
      loadBestSellers();
      loadNewArrivals();
    } else {
      loadProducts();
    }
  }, [products]);

  const [maxProductsToShow, setMaxProductsToShow] = useState(12);
  useEffect(() => {
    const updateProductDisplay = () => {
      if (window.innerWidth >= 1024) {
        setMaxProductsToShow(4); // Desktop
      } else if (window.innerWidth >= 768) {
        setMaxProductsToShow(3); // Tablet
      } else {
        setMaxProductsToShow(4); // Mobilni
      }
    };

    updateProductDisplay();

    window.addEventListener("resize", updateProductDisplay);

    return () => window.removeEventListener("resize", updateProductDisplay);
  }, []);

  return (
    <Wrapper>
      <HeroSection />
      <ProductWrapper>
        <MaxWidthWrapper>
          <ProductSectionTop>
            <ProductSectionTitle>{t("HOME.BEST_SELLERS")}</ProductSectionTitle>
          </ProductSectionTop>
          {bestSellers.length && (
            <ProductGrid>
              {bestSellers.slice(0, maxProductsToShow).map((product, index) => (
                <ProductCardBG key={index} product={product} />
              ))}
            </ProductGrid>
          )}
          <ButtonDiv>
            <Button
              className="button-transparent"
              onClick={() => navigate(goToBestSellers())}
            >
              {t("BUTTONS.VIEW_ALL")}
              <Svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 6.75L9 11.25L4.5 6.75"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </Svg>
            </Button>
          </ButtonDiv>
        </MaxWidthWrapper>
      </ProductWrapper>

      <BannerHorizont />

      <ProductWrapper>
        <MaxWidthWrapper>
          <ProductSectionTop>
            <ProductSectionTitle>{t("HOME.NEW_ARRIVALS")}</ProductSectionTitle>
          </ProductSectionTop>
          {newArrivals.length && (
            <ProductGrid>
              {newArrivals.slice(0, maxProductsToShow).map((product, index) => (
                <ProductCardBG key={index} product={product} />
              ))}
            </ProductGrid>
          )}
          <ButtonDiv>
            <Button
              className="button-transparent"
              onClick={() => navigate(goToNewArrivals())}
            >
              {t("BUTTONS.VIEW_ALL")}
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 6.75L9 11.25L4.5 6.75"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
          </ButtonDiv>
        </MaxWidthWrapper>
      </ProductWrapper>
      <MoreContainer>
        <MaxWidthWrapper>
          <MoreTitle>{t("HOME.WHY_SNUSCO")}</MoreTitle>
          <MoreMid>
            <MoreItem>
              <img
                src={myIcon}
                alt="My Icon"
                width="86"
                height="86"
                style={{ marginBottom: "20px" }}
              />
              <MoreSectionTitle>{t("HOME.PREMIUM_QUALITY")}</MoreSectionTitle>
              <MoreText>{t("HOME.PREMIUM_QUALITY_DESC")}</MoreText>
            </MoreItem>
            <MoreItem>
              <svg
                width="87"
                height="86"
                viewBox="0 0 87 86"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginBottom: "20px" }}
              >
                <path
                  d="M83.8391 35.8783L76.1505 25.3003C75.0337 23.7545 73.2207 22.8319 71.3026 22.8319H61.8577V19.4407C61.8577 17.5874 60.3524 16.082 58.499 16.082H20.954C19.2787 16.082 17.919 17.4254 17.8865 19.0927L14.5117 51.555V51.6602C14.5117 53.5621 16.0414 55.1079 17.9353 55.1564V57.1069C17.9353 61.1454 22.3947 60.7085 22.4756 60.7407C22.249 61.4286 22.1195 62.157 22.1195 62.9178C22.1195 66.7784 25.2597 69.9185 29.1121 69.9185C32.9727 69.9185 36.1128 66.7784 36.1128 62.9178C36.1128 62.157 35.9914 61.4286 35.7648 60.7407H62.9422C62.7156 61.4286 62.5861 62.157 62.5861 62.9178C62.5861 66.7784 65.7262 69.9185 69.5786 69.9185C73.4392 69.9185 76.5794 66.7784 76.5794 62.9178C76.5794 62.157 76.4579 61.4286 76.2313 60.7407C76.3123 60.7083 79.9785 60.6759 79.9785 57.1069V55.1647H81.0954C83.24 55.1647 84.9802 53.4164 84.9802 51.2718V39.399C84.9802 38.1281 84.5836 36.9061 83.8391 35.8783ZM18.008 53.1413C17.2148 53.1413 16.5593 52.502 16.5351 51.7006L19.9018 19.2545C19.9018 19.222 19.9099 19.1817 19.9099 19.1493C19.9099 18.5747 20.3794 18.1052 20.954 18.1052H58.4989C59.2354 18.1052 59.8342 18.7042 59.8342 19.4406V53.1331C59.5186 53.1333 54.6546 53.1332 18.008 53.1413ZM77.9552 57.1071C77.9552 57.9973 77.2349 58.7175 76.3447 58.7175H75.163C73.8843 57.0261 71.861 55.9254 69.5786 55.9254C67.3043 55.9254 65.2811 57.0261 64.0024 58.7175H34.6964C33.4176 57.0261 31.3943 55.9254 29.1119 55.9254C26.8376 55.9254 24.8144 57.0261 23.5357 58.7175C22.9448 58.5961 19.9584 59.3326 19.9584 57.1071V55.1647H77.9552V57.1071ZM82.9568 51.2717C82.9568 52.2995 82.1232 53.1411 81.0954 53.1411C64.8036 53.1331 62.0517 53.1331 61.8576 53.1331V24.855H71.3025C72.5731 24.855 73.7709 25.4621 74.5155 26.4899L82.1961 37.0679C82.6978 37.7478 82.9568 38.5571 82.9568 39.3988V51.2717Z"
                  fill="#5693BF"
                />
                <path
                  d="M80.721 39.4539C80.551 39.7939 80.2029 40.0042 79.8227 40.0042H64.2187C63.6602 40.0042 63.207 39.551 63.207 38.9925V28.3984C63.207 27.8399 63.6602 27.3867 64.2187 27.3867H71.3004C72.1341 27.3867 72.9272 27.7833 73.4209 28.4631L80.6402 38.4016C80.8585 38.7093 80.891 39.114 80.721 39.4539Z"
                  fill="#5693BF"
                />
                <path
                  d="M65.4062 42.9102C64.8474 42.9102 64.3945 43.363 64.3945 43.9218V46.5181C64.3945 47.077 64.8474 47.5298 65.4062 47.5298C65.965 47.5298 66.4179 47.077 66.4179 46.5181V43.9218C66.4179 43.363 65.965 42.9102 65.4062 42.9102Z"
                  fill="#5693BF"
                />
                <path
                  d="M3.03511 21.0702H15.9259C16.4847 21.0702 16.9376 20.6174 16.9376 20.0585C16.9376 19.4997 16.4847 19.0469 15.9259 19.0469H3.03511C2.47628 19.0469 2.02344 19.4997 2.02344 20.0585C2.02344 20.6174 2.47628 21.0702 3.03511 21.0702Z"
                  fill="#5693BF"
                />
                <path
                  d="M15.9242 22.8281H5.42964C4.87081 22.8281 4.41797 23.281 4.41797 23.8398C4.41797 24.3986 4.87081 24.8515 5.42964 24.8515H15.9242C16.483 24.8515 16.9358 24.3986 16.9358 23.8398C16.9358 23.2811 16.483 22.8281 15.9242 22.8281Z"
                  fill="#5693BF"
                />
                <path
                  d="M16.2504 27.7929C16.2504 27.2341 15.7975 26.7812 15.2387 26.7812H3.03511C2.47628 26.7812 2.02344 27.2341 2.02344 27.7929C2.02344 28.3518 2.47628 28.8046 3.03511 28.8046H15.2387C15.7974 28.8046 16.2504 28.3518 16.2504 27.7929Z"
                  fill="#5693BF"
                />
                <path
                  d="M15.1345 31.0976C15.1345 30.5388 14.6817 30.0859 14.1229 30.0859H5.42964C4.87081 30.0859 4.41797 30.5388 4.41797 31.0976C4.41797 31.6564 4.87081 32.1093 5.42964 32.1093H14.1229C14.6817 32.1093 15.1345 31.6564 15.1345 31.0976Z"
                  fill="#5693BF"
                />
                <path
                  d="M39.9665 21.7148C33.7994 21.7148 28.7734 26.7408 28.7734 32.9079C28.7734 39.0751 33.7994 44.093 39.9665 44.093C46.1337 44.093 51.1516 39.0751 51.1516 32.9079C51.1516 26.7408 46.1337 21.7148 39.9665 21.7148ZM46.4412 29.201L38.089 38.0065C37.6358 38.484 36.8668 38.4111 36.5107 37.8689L34.4147 34.672C34.1071 34.2107 34.2366 33.5793 34.7061 33.2718C35.1673 32.9723 35.7987 33.0937 36.1063 33.5632L37.4984 35.6836L44.9765 27.8007C45.3568 27.396 45.9963 27.3799 46.4009 27.7683C46.8054 28.1489 46.8296 28.7883 46.4412 29.201Z"
                  fill="#5693BF"
                />
              </svg>
              <MoreSectionTitle>{t("HOME.FREE_DELIVERY")}</MoreSectionTitle>
              <MoreText>{t("HOME.FREE_DELIVERY_DESC")}</MoreText>
            </MoreItem>
            <MoreItem>
              <svg
                width="87"
                height="86"
                viewBox="0 0 87 86"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginBottom: "20px" }}
              >
                <path
                  d="M19.9898 55.8527C19.6357 54.7524 19.7369 53.6015 20.2554 52.6151L36.1907 21.3516L33.4715 21.5919C32.738 21.6804 32.0425 22.0219 31.5239 22.553L3.47272 51.148C2.22067 52.4254 2.24595 54.4995 3.53598 55.7768L27.7298 79.5154C28.3622 80.1351 29.1969 80.4513 30.0315 80.4513C30.8789 80.4513 31.7263 80.1224 32.3712 79.4648L42.7925 68.8413L22.1019 58.2936C21.1027 57.7877 20.3565 56.9277 19.9898 55.8527Z"
                  fill="#5693BF"
                />
                <path
                  d="M75.6235 33.5806L72.7652 21.6405C70.8808 20.8564 68.8939 20.4644 66.8591 20.4644C66.3645 20.4644 65.8713 20.4896 65.3793 20.5402V20.5529C65.3654 21.275 65.1896 21.9833 64.8482 22.6662C63.9743 24.3862 62.2429 25.3714 60.4343 25.3714C59.6742 25.3714 58.904 25.1956 58.1831 24.8288C55.7423 23.5882 54.7684 20.5921 56.0079 18.1626C56.6149 16.9864 57.6393 16.1011 58.904 15.6964C60.1687 15.293 61.5093 15.3942 62.6842 16C63.5076 16.4173 64.1652 17.037 64.6066 17.7579C65.3541 17.6694 66.1129 17.6188 66.8591 17.6188C68.6043 17.6188 70.323 17.8591 71.9798 18.3523L69.5528 8.18408C69.2999 6.95732 68.3893 5.99613 67.1866 5.66729C66.4784 5.46621 65.6816 5.52819 64.9734 5.83171L40.8314 15.8747C40.1611 16.177 39.6287 16.6955 39.2872 17.3658L21.0894 53.0558C20.6834 53.8273 20.6214 54.7126 20.887 55.5486C21.1652 56.3706 21.747 57.0523 22.5298 57.4456L52.7323 72.8371C53.1989 73.0888 53.7061 73.2038 54.2234 73.2038C54.5649 73.2038 54.9063 73.1533 55.2364 73.0508C56.0699 72.7726 56.7528 72.2047 57.1448 71.4193L75.3313 35.7294C75.6614 35.097 75.7626 34.3382 75.6235 33.5806ZM60.0942 49.1795L53.5569 51.217C53.1357 51.3447 52.7993 51.6761 52.6716 52.1022L50.6442 58.6268C50.3141 59.6917 48.9078 59.9169 48.2691 59.005L44.3119 53.4238C44.0564 53.0621 43.639 52.8547 43.1951 52.8547L36.3531 52.9294C35.2401 52.9496 34.5964 51.6798 35.2641 50.787L39.3593 45.3095C39.6224 44.9604 39.6957 44.5001 39.5489 44.0764L37.3572 37.5885C36.9968 36.535 38.006 35.5321 39.0633 35.89L45.5412 38.0956C45.9648 38.2398 46.4201 38.169 46.778 37.8996L52.2567 33.8121C53.1509 33.1443 54.4143 33.7944 54.3979 34.9023L54.3081 41.738C54.2992 42.1857 54.513 42.6043 54.8759 42.8535L60.4672 46.8006C61.3778 47.4456 61.159 48.8507 60.0942 49.1795Z"
                  fill="#5693BF"
                />
                <path
                  d="M83.5755 30.7357C82.1249 26.2675 79.02 22.6315 74.8338 20.498C70.6476 18.3644 65.881 17.9875 61.4141 19.4394C61.1649 19.5203 61.0283 19.7872 61.1092 20.0363C61.1902 20.2855 61.4571 20.4246 61.7062 20.3411C65.9341 18.9689 70.4428 19.3243 74.4039 21.3428C78.3636 23.3612 81.3015 26.8012 82.6738 31.0279C84.0459 35.2558 83.6905 39.7645 81.6721 43.7255C79.6523 47.6853 76.2136 50.6232 71.9857 51.9954C71.7366 52.0764 71.6013 52.3432 71.6822 52.5924C71.7467 52.7935 71.9326 52.9212 72.1324 52.9212C72.1805 52.9212 72.2298 52.9136 72.2792 52.8972C76.7473 51.4465 80.3833 48.3417 82.5169 44.1555C84.6505 39.9694 85.0261 35.2039 83.5755 30.7357Z"
                  fill="#5693BF"
                />
              </svg>
              <MoreSectionTitle>{t("HOME.SPECIAL_OFFERS")}</MoreSectionTitle>
              <MoreText>{t("HOME.SPECIAL_OFFERS_DESC")}</MoreText>
            </MoreItem>
          </MoreMid>
        </MaxWidthWrapper>
      </MoreContainer>
      <RecentBlogs />
      <Subscription />
    </Wrapper>
  );
};

export default HomeMain;
