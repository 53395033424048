import styled from "styled-components";
import APIService from "../../services/APIService";
import { useState } from "react";
import Warning from "../toast/Warning";
import Success from "../toast/Success";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Wrapper = styled.div`
  border-radius: 8px;

  margin: var(--spacing-xxl) 0;
  width: 94%;

  padding: var(--spacing-xxl) 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing-xxl);

  background: rgba(86, 147, 191, 0.09);
  @media (min-width: 1025px) {
  }
  @media (min-width: 765px) {
    width: var(--max-width-container);
    height: 100px;
    border: 1px solid rgba(86, 147, 191, 0.35);
    padding: var(--spacing-xxl) 0;
    flex-direction: row;
  }
`;
const LeftContainer = styled.div`
  width: 80%;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);

  @media (min-width: 765px) {
    padding: 0 var(--spacing-xl);
    width: 60%;
    max-width: 60%;
    flex-direction: row;
  }
  @media (min-width: 768px) and (max-width: 1040px) {
    //flex-direction: column;
  }
`;
const Title = styled.h3`
  font-family: "Larken-Light";
  text-align: left;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-size: var(--font-size-xxlarge);
  color: var(--text-color);
  cursor: pointer;
`;
const Text = styled.p`
  font-size: var(--font-size-base);
  max-width: 100%;
  @media (min-width: 768px) and (max-width: 1040px) {
    max-width: 80%;
  }
`;

const InputContainer = styled.form`
  position: relative;
  width: 100%;
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: var(--spacing-sm);

  padding: 0 var(--spacing-sm);

  @media (min-width: 768px) and (max-width: 1040px) {
    //width: 160px;
  }
  @media (min-width: 768px) {
    width: 40%;
    max-width: 40%;
    flex-direction: row;
    gap: 0;
  }
`;
const Input = styled.input`
  font-size: var(--font-size-medium);
  font-weight: 400;

  width: calc(90% - var(--spacing-xs) * 2);
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  padding: 0 var(--spacing-xs);
  font-size: var(--font-size-base);
  font-style: normal;
  font-family: Inter;
  border: 1px solid var(--primary-color);
  background-color: var(--background-color);
  &::placeholder {
    color: var(--p-color);

    font-weight: 400;
    line-height: 24px;
  }
  @media (min-width: 768px) {
    width: calc(65% - var(--spacing-xs) * 2);
  }
`;
const Button = styled.button`
  border-radius: 0;
  width: 90%;
  font-size: var(--font-size-base);
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  padding: 0;
  @media (min-width: 768px) {
    width: 30%;
  }
`;
const showSuccessNotification = (text) => {
  toast(<Success text={text} />, {
    position: "top-left",
    autoClose: 5000, // Obaveštenje će se automatski skloniti nakon 5 sekundi
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "custom-toast-success",
    progressClassName: "custom-progress-bar",
  });
};
const showWarningNotification = (text) => {
  toast(<Warning text={text} />, {
    position: "top-left",
    autoClose: 5000, // Obaveštenje će se automatski skloniti nakon 5 sekundi
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "custom-toast-success",
    progressClassName: "custom-progress-bar",
  });
};
const Subscription = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  //const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      //setError("Please enter a valid email address.");
      return;
    }
    //setError("");
    try {
      const response = await APIService.subscribe(email);
      //console.log("Subscription successful:", response);
      showSuccessNotification(t("NOTIFICATIONS.SUBSCRIPTION_SUCCESS"));
      // Optionally, handle success (e.g., show a success message)
      setEmail("");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          showWarningNotification(t("NOTIFICATIONS.EMAIL_ALREADY_SUBSCRIBED"));
        } else {
          showWarningNotification(t("NOTIFICATIONS.SUBSCRIPTION_FAILED"));
        }
      } else {
        showWarningNotification(t("NOTIFICATIONS.SUBSCRIPTION_FAILED"));
      }
      // console.error("Subscription failed:", error);
      // Optionally, handle error (e.g., show an error message)
    }
  };

  const validateEmail = (email) => {
    const trimmedEmail = email.trim();
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(trimmedEmail).toLowerCase());
  };
  return (
    <Container>
      <Wrapper>
        <LeftContainer>
          <svg
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.15992 58.7999H37.9199C32.6399 55.9199 29.0399 50.3999 29.0399 43.9199C29.0399 34.5599 36.7199 26.8799 46.0799 26.8799H46.3199V7.43995C46.3199 4.07995 43.4399 1.19995 40.0799 1.19995H8.15992C4.79992 1.19995 1.91992 4.07995 1.91992 7.43995V52.5599C1.91992 55.9199 4.55992 58.7999 8.15992 58.7999ZM13.6799 10.8H34.3199C35.7599 10.8 36.7199 12 36.7199 13.2C36.7199 14.64 35.7599 15.6 34.3199 15.6H13.6799C12.2399 15.6 11.2799 14.64 11.2799 13.2C11.2799 12 12.4799 10.8 13.6799 10.8ZM13.6799 22.08H27.1199C28.5599 22.08 29.5199 23.2799 29.5199 24.4799C29.5199 25.9199 28.5599 26.8799 27.1199 26.8799H13.6799C12.2399 26.8799 11.2799 25.9199 11.2799 24.4799C11.2799 23.0399 12.4799 22.08 13.6799 22.08Z"
              fill="#5693BF"
            />
            <path
              d="M46.3594 57.3594C53.7376 57.3594 59.7188 51.3782 59.7188 44C59.7188 36.6218 53.7376 30.6406 46.3594 30.6406C38.9812 30.6406 33 36.6218 33 44C33 51.3782 38.9812 57.3594 46.3594 57.3594Z"
              fill="#FEDA2C"
            />
            <path
              d="M46.359 54.298C52.0467 54.298 56.6574 49.6873 56.6574 43.9996C56.6574 38.3119 52.0467 33.7012 46.359 33.7012C40.6713 33.7012 36.0605 38.3119 36.0605 43.9996C36.0605 49.6873 40.6713 54.298 46.359 54.298Z"
              fill="#FCAA17"
            />
            <path
              d="M46.3595 36.0854L48.7016 40.8311L53.9385 41.5921L50.1491 45.2861L51.0437 50.5019L46.3595 48.0393L41.6753 50.5019L42.5699 45.2861L38.7803 41.5921L44.0174 40.8311L46.3595 36.0854Z"
              fill="#FEDA2C"
            />
          </svg>
          <div>
            <Title>{t("SUBSCRIPTION.TITLE")}</Title>
            <Text>{t("SUBSCRIPTION.TEXT")}</Text>
          </div>
        </LeftContainer>
        <InputContainer onSubmit={handleSubmit}>
          <Input
            type="email"
            placeholder={t("PLACEHOLDERS.EMAIL")}
            aria-label="Subscription"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button type="submit">{t("BUTTONS.SUBMIT")}</Button>
        </InputContainer>
      </Wrapper>
    </Container>
  );
};

export default Subscription;
